import { useEffect, useState } from "react";
import LoginForm from "../components/auth/LoginForm";
import LoadingView from "../components/generics/LoadingView";
import Auth from "../api/Auth";
import Cookies from "js-cookie";
import Constants from "../utils/Constants";
import styles from '../style/stylePages/Login.module.css';
import HexParser from "../utils/HexParser";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoginIcon from '@mui/icons-material/Login';


function Login() {
    const [loading, setLoading] = useState(true);
    const [cookieChecked, setCookieChecked] = useState(false);

    const login = async (email: string, password: string, rimaniConnesso: boolean) => {
        setLoading(true);
        let daysToExpire = 1;
        if(rimaniConnesso)
            daysToExpire = 365;
        
        const minutesToExpire = daysToExpire*24*60;
        let data = await Auth.Login(email, password, minutesToExpire);
        if(data) {
            Cookies.set(Constants.nomeCookie.nome!, JSON.stringify(data),{ expires: daysToExpire, domain: Constants.domain.url, secure: true });
            const queryParameters = new URLSearchParams(window.location.search);
            let back = queryParameters.get("callback");
            if(back)
                back = HexParser.hex_to_ascii(back);
            else
                //back = Constants.frontend.url!;
                back ="";
                window.location.replace(back);
            return;
        }
        else {
            setLoading(false);
            toast.error("Username e/o password errati.");
        }
    }

    useEffect(() => {
        if(!cookieChecked) {
            let authCookie = Cookies.get(Constants.nomeCookie.nome!);
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                if(ck) {
                    setCookieChecked(true);
                    const queryParameters = new URLSearchParams(window.location.search);
                    let back = queryParameters.get("callback");
                    if(back)
                        back = HexParser.hex_to_ascii(back);
                    else
                        back = "/";
                        window.location.replace(back);
                    return;
                }
            }
            setCookieChecked(true);
            setLoading(false);
        }
    })

    return (
        <>
            <div className="page-template">
                <div className={styles.mainContentLogin}>  


                <div className="titoloPagina">
                <LoginIcon></LoginIcon><h1>Accesso</h1>
                </div>


                <div className="box box1col">
                        <h2>Login</h2>
                        <LoginForm onLogin={login}/>
                        {/*<div className="testo">Se non sei registrato <a href={"/signup"}>clicca qui.</a></div>*/}
                </div>

                </div>
            </div>



            <LoadingView visible={loading}/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
  }
  
  export default Login;