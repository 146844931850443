import React, { useEffect, useState } from 'react';
import '../../style/styleComponents/UploadFile.css';
import {useDropzone} from 'react-dropzone';
import Files from '../../api/Files';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MessageModal from '../generics/MessageModal';
import { Button } from '@mui/material';


interface ComponentProps {
  infoAbbonamentoInCorso?: Number;
  onChangeinfoAbbonamentoInCorso(): void;
}
export type Props = ComponentProps;



function UploadFile(props: Props) {
  const [loading, setLoading] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);     //Popup per errori
  const [textMessageDialog, setTextMessageDialog] = useState("");

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    noKeyboard: true, accept: {
      'text/csv': [],
    },
    multiple: false
  });

  

  const closeMessageDialog = () => {
    setOpenMessageDialog(false);
  }




  // On file upload (click the upload button)
  const onFileUpload = async () => {
    setLoading(true);
    // Create an object of formData
    const formData = new FormData();


    if (!acceptedFiles || acceptedFiles == null || acceptedFiles.length == 0) {
      setTextMessageDialog("Prima carica un file.");
      setOpenMessageDialog(true);
      return;
    }

    if (props.infoAbbonamentoInCorso == 0) {
      setTextMessageDialog("Ci dispiace ma hai terminato i crediti.");
      setOpenMessageDialog(true);
      return;
    }

    if (props.infoAbbonamentoInCorso == undefined || props.infoAbbonamentoInCorso == null) {
      setTextMessageDialog("Attendi il caricamento del tuo saldo.");
      setOpenMessageDialog(true);
      return;
    }

    // Update the formData object
    formData.append(
      "file",
      acceptedFiles[0],
      acceptedFiles[0].name
    );

    // Request made to the backend api
    const res = await Files.Upload(formData);
    setLoading(false);
  
    if(res.status == 200) {
      toast.success("File inviato. Vai su Downloads per vedere i risultati.");
      props.onChangeinfoAbbonamentoInCorso();
    } 
    else if (res.status >= 300 && res.status != 402) {
      toast.error("Errore nel caricamento del file. Assicurati che il formato del file sia corretto e riprova.");
    }
    else if (res.status == 402) {
      toast.error("Hai terminato i crediti.");
    }

  };



  //Icona dell'elenco file
  const iconaFile = () => {
    if (!acceptedFiles || acceptedFiles == null || acceptedFiles.length == 0) {
      return <img className="icona-csv" src="img/warning.png"/>;
    }
    return <img className="icona-csv" src="img/csv-icon.png"/>;
  }



  return (
    <div className="upload-container">

      <div className="upload-element-esterno">
        <div {...getRootProps({className: 'dropzone'})} className='upload-element-interno'>
                <input {...getInputProps()} />
                <p className="testo">Seleziona il file CSV</p>
        </div>
      </div>

      <div className="file-caricati-esterno">
            <aside className="file-caricati-interno">
                <h4 className="label-filecaricato">File caricato:</h4>
                <ul>{iconaFile()}{acceptedFiles && acceptedFiles[0] ? acceptedFiles[0].name : "Ancora nessun file caricato."}</ul>
            </aside>
      </div>

      <div className="bottone-invia-esterno">
            <Button variant="contained"
                    className="btn-standard"
                    onClick={onFileUpload}
                    disabled={openMessageDialog && props.infoAbbonamentoInCorso ? true : false}
            >
              Fai elaborare all'AI
            </Button>
      </div> 



      <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
        />
      <MessageModal open={openMessageDialog} onClose={closeMessageDialog} titolo={"Attenzione"} testo={textMessageDialog}/>


    </div>
  );
}
export default UploadFile;