// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Righe verticali della tabella*/
.tabella.lista-downloads th,
.tabella.lista-downloads td {
  border: 1px solid rgba(224, 224, 224, 1) !important;
}


/*Head tabella*/
.tabella.lista-downloads .head-tabella th {
    background: var(--secondario);
    color: white;
    font-weight: 600;
}


/*IMG*/
.tabella.lista-downloads img {
    width: 40px;
}



/*STATO*/
.colore-stato-verde {
    color: rgb(19, 184, 19);
    font-weight: 600;
}
.colore-stato-giallo {
    color: rgb(211, 148, 13);
    font-weight: 600;
}
.colore-stato-rosso {
    color: rgb(189, 17, 17);
    font-weight: 600;
}

.download-page a {
    text-decoration: underline !important;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/Downloads.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;;EAEE,mDAAmD;AACrD;;;AAGA,eAAe;AACf;IACI,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;AACpB;;;AAGA,MAAM;AACN;IACI,WAAW;AACf;;;;AAIA,QAAQ;AACR;IACI,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,wBAAwB;IACxB,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,eAAe;AACnB","sourcesContent":["/*Righe verticali della tabella*/\n.tabella.lista-downloads th,\n.tabella.lista-downloads td {\n  border: 1px solid rgba(224, 224, 224, 1) !important;\n}\n\n\n/*Head tabella*/\n.tabella.lista-downloads .head-tabella th {\n    background: var(--secondario);\n    color: white;\n    font-weight: 600;\n}\n\n\n/*IMG*/\n.tabella.lista-downloads img {\n    width: 40px;\n}\n\n\n\n/*STATO*/\n.colore-stato-verde {\n    color: rgb(19, 184, 19);\n    font-weight: 600;\n}\n.colore-stato-giallo {\n    color: rgb(211, 148, 13);\n    font-weight: 600;\n}\n.colore-stato-rosso {\n    color: rgb(189, 17, 17);\n    font-weight: 600;\n}\n\n.download-page a {\n    text-decoration: underline !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
