// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login_mainContentLogin__fBM1n {
    width: -moz-fit-content;
    width: fit-content;
    max-width: 1400px;
    margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/Login.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".mainContentLogin {\n    width: fit-content;\n    max-width: 1400px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContentLogin": `Login_mainContentLogin__fBM1n`
};
export default ___CSS_LOADER_EXPORT___;
