import styles from '../style/stylePages/Home.module.css';
import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Constants from '../utils/Constants';
import AbbonamentoApi from '../api/Abbonamenti';
import { Abbonamento, Mesi } from '../models/Abbonamento';
import LavoraDate from '../utils/LavoraDate';
import { Utente } from '../models/Utente';
import LoadingView from '../components/generics/LoadingView';
import PersonIcon from '@mui/icons-material/Person';
import { writeGlobalValue, useGlobalValue } from 'doa-global-store';


function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cookie, setCookie] = useState<String>();
  const [utente, setUtente] = useState<Utente>();
  const [infoAbbonamentoInCorso, setInfoAbbonamentoInCorso] = useState<Mesi>();


  useEffect(() => {
    if (!cookie) {
      let authCookie = Cookies.get(Constants.nomeCookie.nome!);
      setCookie(authCookie);
      if(authCookie) {
          let ck = JSON.parse(authCookie);
          if(ck) {
            var utenteM : Utente = { _id: ck.user._id, ruolo: ck.user.ruolo, email: ck.user.email, nome: ck.user.nome, cognome: ck.user.cognome };
            setUtente(utenteM);
            return;
          }
      }
    }

    navigate(`/login`);
  }, []);


  useEffect(() => {
    if (!infoAbbonamentoInCorso) {
      AbbonamentoF();
    }
    
  }, [infoAbbonamentoInCorso]);
 




  const AbbonamentoF = async () => {
    setLoading(true);
    try {
      const responseinfoAbbonamentoNow = await AbbonamentoApi.InfoAbbonamentoInCorso();
      if (responseinfoAbbonamentoNow.response && responseinfoAbbonamentoNow.response.status && responseinfoAbbonamentoNow.response.status == 404) {
        let infoAbbonamentoInCorsoNow: Mesi = {
          saldoRimanente: -1,
          dataInizio: "Abbonamento Mancante",
          dataFine: "2023-09-25T00:00:00",
        };
        setInfoAbbonamentoInCorso(infoAbbonamentoInCorsoNow);
      }
      else  {
        if (responseinfoAbbonamentoNow) {
          setInfoAbbonamentoInCorso(responseinfoAbbonamentoNow.data);
        } 
      }

    }
    catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
    setLoading(false);

  }


  const onChangeinfoAbbonamentoInCorso = () => {
    AbbonamentoF();
  }


  

  return (
    <>
      <div className="page-template">
        <div className="main-content">
        
            <div className="titoloPagina">
              <PersonIcon></PersonIcon><h1>Profilo</h1>
            </div>

          <div className="box box1col">
            <h2>Info utente</h2>
            <p>ID utente: {utente ? utente._id : ""}</p>
            <p>Nome: <b>{utente ? utente.nome : ""}</b></p>
            <p>Cognome: <b>{utente ? utente.cognome : ""}</b></p>
            <p>ID utente: <b>{utente ? utente._id : ""}</b></p>
            <p>Email: <b>{utente ? utente.email : ""}</b></p>
          </div>

          <div className="box2col">
            <div className="box box2colint">
              <div><h2>Abbonamento Genera Descrizioni</h2></div>
              {infoAbbonamentoInCorso && infoAbbonamentoInCorso.saldoRimanente! >= 0 ? (
                <div>
                  <div className="saldo-rimanente">
                    <p>
                      Descrizioni ancora generabili questo mese (fino al <b>{infoAbbonamentoInCorso ? LavoraDate.lavoraDataConMese(new Date(infoAbbonamentoInCorso.dataFine!))  : "Attendere il caricamento"}</b>): <b>{infoAbbonamentoInCorso ? infoAbbonamentoInCorso.saldoRimanente!.toString() : "Attendere il caricamento"}</b>
                    </p>
                    <p>
                      Scadenza abbonamento: <b>{infoAbbonamentoInCorso ? LavoraDate.lavoraDataConMese(new Date(infoAbbonamentoInCorso.dataFineAbbonamento!))  : "Attendere il caricamento"}</b>
                    </p>
                  </div>
                </div>
              )
              : "Attualmente non hai un abbonamento in corso"}
            </div>

            <div className="box box2colint">
              <h2>Abbonamento Price Tracking</h2>
              <p>In sviluppo...</p>
            </div>

          </div>


        </div>
        <LoadingView
            visible={loading}
        />
      </div>
    </>
  );
}

export default Home;