import { useEffect, useState } from 'react';
import styles from '../../style/styleComponents/priceTracking/ElencoCompetitors.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { Prodotto } from '../../models/priceTracking/prodottoPriceTracking';
import { Competitor } from '../../models/priceTracking/competitorPriceTracking';
import APICompetitor from '../../api/priceTracking/ApiCompetitor';
import LavoraDate from '../../utils/LavoraDate';
import leggereNumeri from '../../utils/leggereNumeri';
import { cloneDeep, set } from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface ComponentProps {
    prodotto?: Prodotto;
}
export type Props = ComponentProps;


function ElencoCompetitors(props: Props) {
    
    // Utilizza URLSearchParams per ottenere il parametro 'id' dalla query string
    const [competitors, setCompetitors] = useState<Competitor[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState(1); // Stato per la pagina attuale
    const [totalPages, setTotalPages] = useState(1); // Numero totale di pagine
    const [filtri, setFiltri] = useState({ statoCompetitor: true, urlCompetitor: undefined });

    useEffect(() => {
        if (props.prodotto?.urlProdottoCliente) {
          fetchCompetitors(currentPage);
        }
      }, [currentPage, filtri]);


    const fetchCompetitors = async (page: number) => {
      try {
        const response = await APICompetitor.getElencoCompetitor(props.prodotto?.urlProdottoCliente || null, filtri, page, 10, "differenzaPrezzo.percDifferenzaPrezzo", "desc");
        if (Array.isArray(response)) {
          setCompetitors(response);
        } else {
            setCompetitors([]); // Imposta un array vuoto se la risposta non è un array
          }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    }


    //Filtri
    const handleChange = (field: string, isCheckbox: boolean = false ) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const f = cloneDeep(filtri);
      if (isCheckbox) {
        // Se è una checkbox, aggiorna il valore booleano
        set(f, field, event.target.checked ? true : false); 
      } else {
        // Altrimenti, gestisci i valori di input testuali come il nome del prodotto o URL
        set(f, field, event.target.value);
      }
      setFiltri(f);
    }

    // Funzioni per cambiare pagina
    const goToNextPage = () => {
      if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
    };
    const goToPreviousPage = () => {
      if (currentPage > 1) setCurrentPage(prev => prev - 1);
    };



    return (
      <>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div className="filtro-box">
            <Checkbox
              checked={filtri.statoCompetitor === true}
              onChange={handleChange('statoCompetitor', true)}
            />
            <label>Mostra solo competitor attivi</label>
          </div>
          <div className="filtro-box">
            <TextField
              sx={{ backgroundColor: 'white' }}
              value={filtri.urlCompetitor || ''}
              onChange={handleChange('urlCompetitor')}
              label="urlCompetitor" 
              variant="outlined"
              type="search"
            />
          </div>
        </div>
        <br></br>


        <div className="tabella lista-downloads">
                <TableContainer component={Paper} 
                  sx={{ 
                    borderRadius: '10px', 
                    boxShadow: 'none', 
                    overflow: 'hidden', 
                    border: '1px solid #ddd' // Aggiunge un bordo visibile
                  }}>
                    <Table     sx={{ 
                      minWidth: 650, 
                      borderCollapse: "separate", // Importante per non sovrapporre i bordi
                      borderSpacing: 0 // Mantiene i bordi uniti
                    }} 
                    aria-label="simple table">
                    <TableHead className="head-tabella">
                        <TableRow>
                        <TableCell>Azioni</TableCell>
                        <TableCell>Stato</TableCell>
                        <TableCell>Nome Competitor</TableCell>
                        <TableCell>Url Competitor</TableCell>
                        <TableCell>Ultimo prezzo</TableCell>
                        <TableCell>Diff prezzo</TableCell>
                        <TableCell>Ultima analisi</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {competitors.map((competitor) => (
                            <TableRow key={competitor._id}>
                            <TableCell>-</TableCell>
                            <TableCell>{competitor.statoCompetitor ? "Attivo" : "Disattivo"}</TableCell>
                            <TableCell>{competitor.nomeCompetitor ? competitor.nomeCompetitor : "Nessun nome"}</TableCell>
                            <TableCell>                        
                              <a 
                                href={competitor.urlCompetitor} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                onClick={(event) => event.stopPropagation()} // Blocca la propagazione del click
                                className={styles.linkEsterno}
                              >
                                {competitor.urlCompetitor}
                                <OpenInNewIcon />
                              </a>
                            </TableCell>
                            <TableCell>
                                {competitor.ultimoPrezzoCompetitor?.prezzo ? leggereNumeri.lavoraDecimal128(competitor.ultimoPrezzoCompetitor.prezzo) + "€" : "N/A"}
                            </TableCell>
                            <TableCell 
                              style={{
                                backgroundColor:
                                  competitor.differenzaPrezzo?.percDifferenzaPrezzo
                                    ? parseFloat(leggereNumeri.lavoraDecimal128(competitor.differenzaPrezzo?.percDifferenzaPrezzo)) > 0
                                      ? "lightcoral"
                                      : parseFloat(leggereNumeri.lavoraDecimal128(competitor.differenzaPrezzo?.percDifferenzaPrezzo)) < 0
                                      ? "lightgreen"
                                      : "transparent"
                                    : "transparent" 
                              }}>
                                {competitor.differenzaPrezzo?.percDifferenzaPrezzo ? leggereNumeri.lavoraDecimal128(competitor.differenzaPrezzo?.percDifferenzaPrezzo) + "%" : "N/A"}
                            </TableCell>
                            <TableCell>{competitor.ultimoPrezzoCompetitor?.data ? LavoraDate.lavoraDataConMese(competitor.ultimoPrezzoCompetitor.data) : "N/A"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    </Table>
                </TableContainer>
        </div>

        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button onClick={goToPreviousPage} disabled={currentPage === 1}>Precedente</Button>
              <span style={{ margin: "0 10px" }}>Pagina {currentPage} di {totalPages}</span>
              <Button onClick={goToNextPage} disabled={currentPage === totalPages}>Successiva</Button>
        </div>



        </>
    );
}
export default ElencoCompetitors;