import { useEffect, useState } from 'react';
import { cloneDeep, set } from 'lodash';
import { LineChart } from '@mui/x-charts';
import { Prodotto } from '../../models/priceTracking/prodottoPriceTracking';
import { Competitor } from '../../models/priceTracking/competitorPriceTracking';
import APICompetitor from '../../api/priceTracking/ApiCompetitor';
import leggereNumeri from '../../utils/leggereNumeri';

interface ComponentProps {
    prodotto?: Prodotto;
}
export type Props = ComponentProps;

const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('it-IT');
};

function GraficoPrezzi(props: Props) {
    const [loading, setLoading] = useState<boolean>(true);
    const [filtri, setFiltri] = useState({ statoCompetitor: true, urlCompetitor: undefined });
    const [competitors, setCompetitors] = useState<Competitor[]>([]);

    useEffect(() => {
        if (props.prodotto?.urlProdottoCliente) {
            fetchCompetitors();
        }
    }, [props.prodotto]);

    const fetchCompetitors = async () => {
        try {
            const f = cloneDeep(filtri);
            set(f, "statoCompetitor", true);
            setFiltri(f);
            const response = await APICompetitor.getElencoCompetitor(
                props.prodotto?.urlProdottoCliente || null,
                filtri, -1, -1,
                "differenzaPrezzoMedio.percDifferenzaPrezzoMedio", "desc"
            );
            setCompetitors(Array.isArray(response) ? response : []);
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    const getPrezziPerGrafico = () => {
        let prezziProdotto = props.prodotto?.prezziProdottoCliente || [];
        let prezziCompetitor = competitors.flatMap(c => c.prezziCompetitor || []);

        let dateUniche = new Set<number>();
        prezziProdotto.forEach(p => {
            if (p.dataProdottoCliente) {
                dateUniche.add(new Date(p.dataProdottoCliente).setHours(0, 0, 0, 0));
            }
        });
        prezziCompetitor.forEach(p => {
            if (p.dataProdottoCompetitor) {
                dateUniche.add(new Date(p.dataProdottoCompetitor).setHours(0, 0, 0, 0));
            }
        });

        let dateOrdinate = Array.from(dateUniche).sort((a, b) => a - b);

        let dataset = dateOrdinate.map(date => {
            let dataEntry: any = { date };

            let prodottoPrezzo = prezziProdotto.find(p =>
                p.dataProdottoCliente && new Date(p.dataProdottoCliente).setHours(0, 0, 0, 0) === date
            );
            dataEntry['prodotto'] = prodottoPrezzo
                ? parseFloat(leggereNumeri.lavoraDecimal128(prodottoPrezzo.prezzoProdottoCliente))
                : null;

            competitors.forEach((competitor, index) => {
                let prezzoCompetitor = competitor.prezziCompetitor?.find(p =>
                    p.dataProdottoCompetitor && new Date(p.dataProdottoCompetitor).setHours(0, 0, 0, 0) === date
                );
                dataEntry[`competitor-${competitor.idUtente}-${index}`] = prezzoCompetitor
                    ? parseFloat(leggereNumeri.lavoraDecimal128(prezzoCompetitor.prezzoProdottoCompetitor))
                    : null;
            });
            return dataEntry;
        });

        return dataset;
    };

    let dataset = getPrezziPerGrafico();

    const series = [
        { id: 'Prodotto', dataKey: 'prodotto', label: 'Il tuo Prodotto', connectNulls: true },
        ...competitors.map((competitor, index) => ({
            id: `competitor-${competitor.idUtente}-${index}`,
            dataKey: `competitor-${competitor.idUtente}-${index}`,
            label: competitor.nomeCompetitor || `${competitor._id}`,
            connectNulls: true,
        }))
    ];

    return (
        <div style={{ height: '450px', width: '100%' }}>
            <LineChart
                dataset={dataset}
                xAxis={[{
                    id: 'Date',
                    dataKey: 'date',
                    scaleType: 'time',
                    valueFormatter: (date) => formatDate(date),
                }]}
                series={series}
                slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'top', horizontal: 'left' },
                      padding: 0,
                    },
                  }}
            />
        </div>
    );
}

export default GraficoPrezzi;
