import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate, useLocation } from 'react-router-dom';
import Constants from '../utils/Constants';
import { Utente } from '../models/Utente';
import UploadFilePriceTracking from '../components/priceTracking/UploadFilePriceTracking';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import ElencoProdotti from '../components/priceTracking/ElencoProdotti';
import AnalyticsIcon from '@mui/icons-material/Analytics';

function PriceTracking() {
    const navigate = useNavigate();
    const location = useLocation(); // Ottieni la location per leggere i parametri della query
    const [cookie, setCookie] = useState<string | undefined>();
    const [utente, setUtente] = useState<Utente | undefined>();

    // Funzione per ottenere il valore del parametro 'id' dalla query string
    const getQueryParam = (param: string) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    const id = getQueryParam("id"); // Ottieni l'ID dalla query string

    useEffect(() => {
        if (!cookie) {
            let authCookie = Cookies.get(Constants.nomeCookie.nome!);
            setCookie(authCookie);
            if (authCookie) {
                let ck = JSON.parse(authCookie);
                if (ck) {
                    var utenteM: Utente = {
                        _id: ck.user._id,
                        ruolo: ck.user.ruolo,
                        nome: ck.user.nome,
                        cognome: ck.user.cognome
                    };
                    setUtente(utenteM);
                    return;
                }
            }
        }
        navigate(`/login`);
    }, []);

    return (
        <div className="page-template">
            <div className="main-content pagina-impostazioni">
                
                    <div className="titoloPagina">
                        <AnalyticsIcon></AnalyticsIcon>
                        <div>
                        <h1>Price Tracking</h1>
                        <p>Traccia i prezzi dei competitor</p>
                        </div>
                    </div>

                <div className="box box1col">
                    <h2>Istruzioni</h2>
                    <ol>
                        <li><a href="https://doagency.it/wp-content/uploads/2025/03/csv-esempio-input-pricetracking.csv">Scarica il CSV di esempio</a></li>
                        <li>Compila il CSV scaricato, con i prodotti che vuoi monitorare.</li>
                        <li>Inviaci il file CSV.</li>
                        <li>Attendi il giorno dopo per avere le informazioni aggiornate.</li>
                        <li>I dati saranno aggiornati ogni giorno.</li>
                    </ol>
                    </div>


                {id && utente?.ruolo === "admin" && ( //Lo mostra solo se id è nell'url e ruolo utente nel cookie è admin
                    <div className="box box1col">
                        <h2>Carica CSV dei prodotti</h2>
                        <UploadFilePriceTracking utenteId = {id} />
                    </div>
                )}


                <div className="box box1col">
                    <h2>I tuoi prodotti</h2>
                    <ElencoProdotti />
                </div>


            </div>
        </div>
    );
}

export default PriceTracking;
