import '../style/Footer.css';


function Footer() {
 
  return (
    <div className="footer-container">
        <div className="footer-interno">
            <p></p>
        </div> 
    </div>
  );
}
export default Footer;