import '../../style/stylePages/Downloads.css';
import Files from '../../api/Files';
import { File } from '../../models/File';
import LavoraDate from '../../utils/LavoraDate';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import LoadingView from '../generics/LoadingView';
import { useLocation, useNavigate } from 'react-router-dom';


function Downloads() {
  const [loading, setLoading] = useState(true);
  const [elencoFile, setElencoFile] = useState<File[]>();
  const [isAdmin, setIsAdmin] = useState(false);

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState<Set<string>>(new Set());  //Per le checkbox
  const idUtenteParam = queryParameters.get("id");

  useEffect(() => {
    if(idUtenteParam)
      setIsAdmin(true);
    if (!elencoFile) {
      Files.DownloadTuttiFile(idUtenteParam).then(
        res=>{
          setElencoFile(res?.data);
          setLoading(false);
        }
      );
    }
  }, [idUtenteParam, elencoFile]);


  const scaricaFile = async (nomeBlobFile: string, nomeFileCaricato: string) => {
    //Verifico se c'è param dell'id dell'utente
    let id = "";
    if (idUtenteParam) {
      id = idUtenteParam;
    }

    if (nomeBlobFile) {
      const response = await Files.DownloadSingoloFile(nomeBlobFile, id)
      if (response) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = nomeFileCaricato
        link.click()
      }

    }
  }



  const mostraStato = (stato: string) => {
    if (!stato) return <div className="colore-stato-grigio">Stato non disponibile</div>;
  
    let coloreStato = "colore-stato-rosso";
    switch (stato) {
      case "Completato":
        coloreStato = "colore-stato-verde";
        break;
      case "In lavorazione":
        coloreStato = "colore-stato-giallo";
        break;
      case "Errore":
        coloreStato = "colore-stato-rosso";
        break;
      default:
        coloreStato = "colore-stato-grigio"; // Stato sconosciuto
    }
    return <div className={coloreStato}>{stato}</div>;
  };


  const eliminaFile = async (_id: string, nomeBlobFile: string) => {
    try {
      const response = await Files.EliminaFile(_id, nomeBlobFile);

      if (response === undefined) {
        throw new Error('Response è undefined');
      }

      toast.success("File eliminato.");
      setElencoFile(undefined);
    } catch (error) {
      toast.error("C'è stato un errore.");
    }
  }


  const handleCheckboxChange = (id: string) => {
    setSelectedFiles((prev) => {
      const updated = new Set(prev);
      if (updated.has(id)) {
        updated.delete(id);
      } else {
        updated.add(id);
      }
      return updated;
    });
  }

  //Cancellazone massiva
  const handleMassDelete = async () => {
    try {
      const filesArray = Array.from(selectedFiles); // Converte il Set in un array
      const response = await Files.EliminaTantiFile(filesArray);

      if (response === undefined) {
        throw new Error('Response è undefined');
      }
      toast.success("File eliminati.");
      setElencoFile(undefined);
    } catch (error) {
      toast.error("C'è stato un errore.");
    }
  }



  return (
    <div className="">
      <div className="download-page">
        <a onClick={() => navigate(-1)} style={{display: isAdmin? 'block' : 'none'}}>Indietro</a>
        <div>
          <p></p>
          <p>Refresha la pagina se continui a vedere lo stato "in lavorazione".</p>
        </div>

        {/* Bottone per la cancellazione massiva */}
        <div className="actions">
          <Button
            variant="contained"
            color="error"
            onClick={handleMassDelete}
            disabled={selectedFiles.size === 0}
          >
            Cancellazione Massiva
          </Button>
        </div>
        <br></br>

        <div className="tabella lista-downloads">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="head-tabella">
                        <TableRow>
                            <TableCell>Tipo di file</TableCell>
                            <TableCell>Id blob</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Stato</TableCell>
                            <TableCell>N. prodotti</TableCell>
                            <TableCell>N. prodotti elaborati</TableCell>
                            <TableCell>Nome file</TableCell>
                            <TableCell>Azione</TableCell>
                            <TableCell>Elimina</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
              </TableHead>
              {elencoFile && elencoFile.length > 0 ? 
              <TableBody>
                        {elencoFile?.map((row: File) => {
                            const dataPrenotazione = LavoraDate.lavoraDataConMeseEOrario(row.dataCaricamento!)

                            return (
                                <TableRow
                                    hover
                                    key={row._id}
                                    className="table-row"
                                    sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                                >
                                    <TableCell><div className="immagine"><img src="../img/csv-icon.png" /></div></TableCell>
                                    <TableCell>{row.nomeBlobFile}</TableCell>
                                    <TableCell>{dataPrenotazione}</TableCell>
                                    <TableCell>{mostraStato(row.stato!)}</TableCell>
                                    <TableCell>{row.numeroRighe}</TableCell>
                                    <TableCell>{row.numeroRigheElaborate}</TableCell>
                                    <TableCell>{row.nomeFileCaricato}</TableCell>
                                    <TableCell>
                                      <button 
                                        disabled={row.stato != "Completato" && row.stato != "Completato con errori" ? true : false} 
                                        className="btn-standard" 
                                        onClick={()=>scaricaFile(row.nomeBlobFile!, row.nomeFileCaricato!)}
                                        >Scarica il file
                                      </button>
                                    </TableCell>
                                    <TableCell>
                                      <button 
                                        disabled={row.stato == "In lavorazione" ? true : false}
                                        className='btn-standard btn-rosso'
                                        onClick={()=>eliminaFile(row._id!, row.nomeBlobFile!)}
                                      >
                                        Elimina
                                      </button></TableCell>
                                      <TableCell>
                                        <Checkbox
                                          checked={selectedFiles.has(row._id!)}
                                          onChange={() => handleCheckboxChange(row._id!)}
                                        />
                                      </TableCell>
                                </TableRow>
                            )
                        })}
              </TableBody>

            : null}

            </Table>
          </TableContainer>
          {elencoFile && elencoFile.length === 0 && <p>Nessun file trovato.</p>}
        </div>




        <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        

      </div>
      <LoadingView
          visible={loading}
      />
    </div>
  );
}

export default Downloads;