// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Pagina Prodotto - Price Tracking*/
.ElencoCompetitors_prezzoClienteEvidenza__Budbp {
    font-size: 16px;
    font-weight: 700;
    color: green;
}


/*Tabella elenco competitors*/
.ElencoCompetitors_linkEsterno__CoJlI {
    display: "flex"; 
    alignItems: "center";
    gap: "4px";
    color: gray;
    display: inline-block;
    max-width: 300px; /* Imposta la larghezza massima del link */
    direction: rtl; /* Imposta la direzione del testo da destra a sinistra */
    text-overflow: ellipsis; /* Aggiunge i puntini di sospensione */
    overflow: hidden; /* Nasconde il testo che eccede la larghezza */
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/style/styleComponents/priceTracking/ElencoCompetitors.module.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;;AAGA,6BAA6B;AAC7B;IACI,eAAe;IACf,oBAAoB;IACpB,UAAU;IACV,WAAW;IACX,qBAAqB;IACrB,gBAAgB,EAAE,0CAA0C;IAC5D,cAAc,EAAE,wDAAwD;IACxE,uBAAuB,EAAE,sCAAsC;IAC/D,gBAAgB,EAAE,8CAA8C;IAChE,mBAAmB;AACvB","sourcesContent":["/*Pagina Prodotto - Price Tracking*/\n.prezzoClienteEvidenza {\n    font-size: 16px;\n    font-weight: 700;\n    color: green;\n}\n\n\n/*Tabella elenco competitors*/\n.linkEsterno {\n    display: \"flex\"; \n    alignItems: \"center\";\n    gap: \"4px\";\n    color: gray;\n    display: inline-block;\n    max-width: 300px; /* Imposta la larghezza massima del link */\n    direction: rtl; /* Imposta la direzione del testo da destra a sinistra */\n    text-overflow: ellipsis; /* Aggiunge i puntini di sospensione */\n    overflow: hidden; /* Nasconde il testo che eccede la larghezza */\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prezzoClienteEvidenza": `ElencoCompetitors_prezzoClienteEvidenza__Budbp`,
	"linkEsterno": `ElencoCompetitors_linkEsterno__CoJlI`
};
export default ___CSS_LOADER_EXPORT___;
