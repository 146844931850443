import { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from '../style/styleComponents/SidebarLeft.module.css';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import HomeIcon from '@mui/icons-material/Home';
import CreateIcon from '@mui/icons-material/Create';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function SidebarLeft() {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sidebarCollapsed');
    return savedState === 'true';
  });

  const toggleSidebar = () => {
    const newState = !collapsed;
    setCollapsed(newState);
    localStorage.setItem('sidebarCollapsed', newState.toString());
  };

  // Funzione per determinare se un link è attivo
  const isActive = (path: string): boolean => {
    if (path === '/') {
      return location.pathname === '/';
    }
    return location.pathname.includes(path);
  };
  

  return (
    <div className={`${styles.sidebarContainer} ${collapsed ? styles.collapsed : ""}`}>
      <div className={`${styles.contenutoSidebar} ${collapsed ? styles.collapsed : ""}`}>
        <div className={styles.chiudiSidebar} onClick={toggleSidebar} style={{ cursor: "pointer" }}>
          <MenuOpenIcon />
        </div>

        <div className={`${styles.content} ${collapsed ? styles.collapsedContent : ""}`}>

          <Link to="/">
            <div className={`${styles.riga} ${isActive('/') ? styles.active : ''}`}>
              <div><PersonIcon /></div>
              <div className={`${styles.scritta} ${collapsed ? styles.collapsedScritta : ""}`}>Profilo</div>
            </div>
          </Link>

          <hr className={styles.divider} />

          <Link to="/descrizioniai">
            <div className={`${styles.riga} ${isActive('/descrizioniai') ? styles.active : ''}`}>
              <div><CreateIcon /></div>
              <div className={`${styles.scritta} ${collapsed ? styles.collapsedScritta : ""}`}>Descrizioni AI</div>
            </div>
          </Link>

          <Link to="/pricetracking">
            <div className={`${styles.riga} ${isActive('/pricetracking') ? styles.active : ''}`}>
              <div><AnalyticsIcon /></div>
              <div className={`${styles.scritta} ${collapsed ? styles.collapsedScritta : ""}`}>Price tracking</div>
            </div>
          </Link>

          <Link to="/logout">
            <div className={`${styles.riga} ${styles.logout} ${isActive('/logout') ? styles.active : ''}`}>
              <div><ExitToAppIcon /></div>
              <div className={`${styles.scritta} ${collapsed ? styles.collapsedScritta : ""}`}>Logout</div>
            </div>
          </Link>

        </div>
      </div>
    </div>
  );
}

export default SidebarLeft;
