import React, { useEffect, useState } from 'react';
import styles from '../../style/styleComponents/priceTracking/ElencoProdotti.module.css';
import Prodotti from '../../api/priceTracking/ApiProdotti';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MessageModal from '../generics/MessageModal';
import { Button, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import LoadingView from '../generics/LoadingView';
import { useLocation, useNavigate } from 'react-router-dom';
import { Prodotto } from '../../models/priceTracking/prodottoPriceTracking';
import LavoraDate from '../../utils/LavoraDate';
import leggereNumeri from '../../utils/leggereNumeri';
import { cloneDeep, set } from "lodash";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';


function ElencoProdotti() {
  const [loading, setLoading] = useState(false);
  const [elencoProdotti, setElencoProdotti] = useState<Prodotto[]>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Stato per la pagina attuale
  const [totalPages, setTotalPages] = useState(1); // Numero totale di pagine

  const [filtri, setFiltri] = useState({statoProdottoCliente: true, nomeProdotto: undefined, urlProdotto: undefined});

  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const idUtenteParam = queryParameters.get("id");


    useEffect(() => {
      if (idUtenteParam) setIsAdmin(true);
        fetchProdotti(currentPage);
    }, [currentPage, filtri]); 


  const fetchProdotti = (page: number) => {
    Prodotti.getElencoProdotti(idUtenteParam, filtri, page, 10, "differenzaPrezzoMedio.percDifferenzaPrezzoMedio", "desc")
      .then(res => {
        console.log("Risposta API:", res.data); // DEBUG
        setElencoProdotti(res?.data.data ?? []); 
        setTotalPages(res?.data.totalPages ?? 1); // Verifica che totalPages arrivi correttamente
      })
      .catch(err => {
        console.error("Errore nel recupero dei prodotti:", err);
        setElencoProdotti([]);
      })
  };
  // Funzioni per cambiare pagina
  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(prev => prev + 1);
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(prev => prev - 1);
  };


  const handleRowClick = (row: Prodotto) => {
    navigate(`/pricetrackingprodotto?url=${row.urlProdottoCliente}`);
  };



  //Filtri
  const handleChange = (field: string, isCheckbox: boolean = false) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const f = cloneDeep(filtri);
    if (isCheckbox) {
      // Se è una checkbox, aggiorna il valore booleano
      set(f, field, event.target.checked ? true : false); 
    } else {
      // Altrimenti, gestisci i valori di input testuali come il nome del prodotto o URL
      set(f, field, event.target.value);
    }
    setFiltri(f);
  };



  return (
    <div className="">
      <div className="main-content download-page">
        <a onClick={() => navigate(-1)} style={{display: isAdmin? 'block' : 'none'}}>Indietro</a>


        <div style={{ display: 'flex', gap: '20px' }}>
          <div className="filtro-box">
            <Checkbox
              checked={filtri.statoProdottoCliente === true}
              onChange={handleChange('statoProdottoCliente', true)}
            />
            <label>Mostra solo prodotti attivi</label>
          </div>
          <div className="filtro-box">
            <TextField
              sx={{ backgroundColor: 'white' }}
              value={filtri.nomeProdotto || ''}
              onChange={handleChange('nomeProdotto')}
              label="Nome Prodotto" 
              variant="outlined"
              type="search"
            />
          </div>
          <div className="filtro-box">
            <TextField
              sx={{ backgroundColor: 'white' }}
              value={filtri.urlProdotto || ''}
              onChange={handleChange('urlProdotto')}
              label="UrlProdotto" 
              variant="outlined"
              type="search"
            />
          </div>
        </div>
        <br></br>

        <div className="tabella lista-downloads">
          <TableContainer component={Paper} sx={{ 
                    borderRadius: '10px', 
                    boxShadow: 'none', 
                    overflow: 'hidden', 
                    border: '1px solid #ddd' // Aggiunge un bordo visibile
                  }}>
            <Table sx={{ 
                      minWidth: 650, 
                      borderCollapse: "separate", // Importante per non sovrapporre i bordi
                      borderSpacing: 0 // Mantiene i bordi uniti
                    }} >
              <TableHead className="head-tabella">
                <TableRow>
                  <TableCell>Azioni</TableCell>
                  <TableCell>Nome prodotto</TableCell>
                  <TableCell>Stato</TableCell>
                  <TableCell>Ultimo prezzo</TableCell>
                  <TableCell>Competitors attivi</TableCell>
                  <TableCell>Prezzo medio competitor</TableCell>
                  <TableCell>Diff</TableCell>
                  <TableCell>Url</TableCell>
                  <TableCell>Ultima analisi</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {elencoProdotti && elencoProdotti.length > 0 ? (
                  elencoProdotti.map((row: Prodotto) => (
                    <TableRow
                      hover
                      key={row._id}
                      className="table-row"
                      sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                      onClick={() => handleRowClick(row)} // Aggiungi il click alla riga
                    >
                      <TableCell>-</TableCell>
                      <TableCell>{row.nomeProdottoCliente}</TableCell>
                      <TableCell>{row.statoProdottoCliente ? "Attivo" : "Disattivo"}</TableCell>
                      <TableCell>{row.ultimoPrezzoCliente ? leggereNumeri.lavoraDecimal128(row.ultimoPrezzoCliente.prezzoUltimoPrezzoCliente) + "€" : "N/A"}</TableCell>
                      <TableCell>{row.numeroCompetitorAttivi ? row.numeroCompetitorAttivi.toString() : "0"}</TableCell>
                      <TableCell>
                        {row.prezzoMedioCompetitor?.prezzoPrezzoMedioCompetitor ? leggereNumeri.lavoraDecimal128(row.prezzoMedioCompetitor.prezzoPrezzoMedioCompetitor) + "€" : "N/A"}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor:
                            row.differenzaPrezzoMedio?.percDifferenzaPrezzoMedio
                              ? parseFloat(leggereNumeri.lavoraDecimal128(row.differenzaPrezzoMedio.percDifferenzaPrezzoMedio)) > 0
                                ? "lightcoral"
                                : parseFloat(leggereNumeri.lavoraDecimal128(row.differenzaPrezzoMedio.percDifferenzaPrezzoMedio)) < 0
                                ? "lightgreen"
                                : "transparent"
                              : "transparent" 
                        }}>
                          {row.differenzaPrezzoMedio?.percDifferenzaPrezzoMedio ? leggereNumeri.lavoraDecimal128(row.differenzaPrezzoMedio.percDifferenzaPrezzoMedio) + "%" : "N/A"}
                      </TableCell>
                      <TableCell>  
                          <a 
                          href={row.urlProdottoCliente} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          onClick={(event) => event.stopPropagation()} // Blocca la propagazione del click
                          className={styles.linkEsterno}
                        >
                          {row.urlProdottoCliente}
                        </a>
                      </TableCell>
                      <TableCell>
                        {row.ultimoPrezzoCliente?.dataUltimoPrezzoCliente ? LavoraDate.lavoraDataConMese(row.ultimoPrezzoCliente?.dataUltimoPrezzoCliente) : "N/A"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">Nessun Prodotto</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        

        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <Button onClick={goToPreviousPage} disabled={currentPage === 1}>Precedente</Button>
          <span style={{ margin: "0 10px" }}>Pagina {currentPage} di {totalPages}</span>
          <Button onClick={goToNextPage} disabled={currentPage === totalPages}>Successiva</Button>
        </div>


        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>

      <LoadingView visible={loading} />
    </div>
  );
}
export default ElencoProdotti;