import { Outlet, useLocation } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style/Colori-Typography.css';
import '../style/Layout.css';
import Menubar from '../components/Menubar';
import SidebarLeft from '../components/SidebarLeft';
import Footer from "../components/Footer";

const Layout = () => {
  const location = useLocation();

  // Verifica se la route è "/login" o "/recupera" per nascondere SidebarLeft
  const hideSidebar = location.pathname === '/login' || location.pathname === '/recupera';

  return (
    <>
      <div className="blocco-esterno">
        <Menubar />
        <div className="blocco-contenuto">
          {/* Rendi visibile SidebarLeft solo se NON siamo nella pagina di login */}
          {!hideSidebar && <SidebarLeft />}
          {/*Contenuto delle pagine:*/}
          <div className="contenuto-principale">
            <Outlet />
          </div>
        </div>
        {/*Footer:*/}
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Layout;