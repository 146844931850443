import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Utente } from '../../models/Utente';
import ApiUtentiAdmin from '../../api/UtentiAdmin';
import ApiAbbonamenti from '../../api/Abbonamenti';
import LoadingView from '../../components/generics/LoadingView';
import '../../style/stylePages/admin/UtenteInfo.css';
import { Button } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AbbonamentiUtente from '../../components/admin/AbbonamentiUtente';
import DialogAbbonamento from '../../components/admin/DialogAbbonamento';
import { Abbonamento, Mesi } from '../../models/Abbonamento';


function UtenteInfo() {
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState<String>();
    const [utente, setUtente] = useState<Utente>();
    const [abbonamentoUtente, setAbbonamentoUtente] = useState<Abbonamento>();
    const [abbonamenti, setAbbonamenti] = useState<Mesi[]>();

    const [abbonamentoSelezionato, setAbbonamentoSelezionato] = useState<Mesi>();

    const [openDialog, setOpenDialog] = useState(false);    //Popup di modifica/aggiunta

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);

    const navigate = useNavigate();


    const getUtente = async (idUtente: string | null) => {
        if(idUtente !== null) {
            setLoading(true);
            const pren: Utente = await ApiUtentiAdmin.getSingoloUtente(idUtente);
            setUtente(pren);
            setLoading(false);
        }
    }

    const autorizza = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                //await ApiUtentiAdmin.autorizzaVisibilitaInfermiere(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Infermiere autorizzato!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile autorizzare l'infermiere!");
            }
            
        }
    }

    const rimuoviAutorizzazione = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                //await ApiUtentiAdmin.rimuoviVisibilitaInfermiere(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Autorizzazione infermiere revocata!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile revocare l'autorizzazione infermiere!");
            }
            
        }
    }

    const abilita = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                await ApiUtentiAdmin.autorizzaAccesso(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Infermiere abilitato!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile autorizzare l'infermiere!");
            }
            
        }
    }

    const disabilita = async () => {
        if(utente && utente._id) {
            try {
                setLoading(true);
                await ApiUtentiAdmin.rimuoviAccesso(utente.email!);
                setLoading(false);
                setUtente(undefined);
                toast.success("Infermiere disabilitato!");
            } catch (err: any) {
                setLoading(false);
                console.log(err);
                toast.error("Impossibile revocare l'autorizzazione infermiere!");
            }
            
        }
    }

    const getAbbonamenti = async (idUtente: string) => {
        let res = await ApiAbbonamenti.getListaAbbonamenti(idUtente);
        if(res) {
            setAbbonamentoUtente(res.data);
            setAbbonamenti(res.data?.mesi.sort((a: Mesi, b: Mesi) => {
                let dataA = new Date(a.dataInizio!);
                let dataB = new Date(b.dataInizio!);
            
                return dataB.getTime() - dataA.getTime();
            }));
            //setTotalPages(data.totalPages)
        }
    }


    const openDialogFunc = (abbonamento?: Mesi) => {
        if(abbonamento) {
            setAbbonamentoSelezionato(abbonamento);
        }
        setOpenDialog(true);
    }

    const closeDialogAndRefresh = () => {
        setAbbonamenti(undefined);
        setAbbonamentoSelezionato(undefined);
        setOpenDialog(false);
    }

    const closeDialog = () => {
        setAbbonamentoSelezionato(undefined);
        setOpenDialog(false);
    }


    useEffect(() => {
        const id = queryParameters.get("id");
        const t = queryParameters.get("t");
        if(t) 
            setTab(t);

        if(!utente) {
            getUtente(id);
        }

        if(!abbonamenti && id) {
            getAbbonamenti(id);
        }
    })

    return(
        <div className="page-template">
            <div className='main-content utente-info'>
                <h2>Info Utente</h2>
                <a onClick={() => navigate(`/utenti`)}>Indietro</a>
                <div className='flex-container'>
                    <div>
                        <div className='flex-container-row'><b>Id Utente: </b><p>{utente?._id}</p></div>
                        <div className='flex-container-row'><b>Email: </b><p>{utente?.email}</p></div>
                        <div className='flex-container-row'><b>Nome: </b><p>{utente?.nome}</p></div>
                        <div className='flex-container-row'><b>Cognome: </b><p>{utente?.cognome}</p></div>
                    </div>
                    <div>
                        <div className='flex-container-row'><b>Codice Fiscale: </b><p>{utente?.cf}</p></div>
                        <div className='flex-container-row'><b>Telefono: </b><p>{utente?.telefono}</p></div>
                        <div className='flex-container-row'><b>Azienda: </b><p>{utente?.company}</p></div>
                        <div className='flex-container-row'>
                            <b>Verificato: </b>
                            <p className={utente?.verificatoUtente ? 'green-label' : 'red-label'}>{utente?.verificatoUtente ? "Verificato" : "Non verificato"}</p>
                        </div>
                        <div className='flex-container-row'>
                            <b>Attivo: </b>
                            <p className={utente?.autorizzatoUtente ? 'green-label' : 'red-label'}>{utente?.autorizzatoUtente ? "Attivo" : "Non attivo"}</p>
                        </div>
                    </div>
                </div>


                <div style={{display: "flex", justifyContent: "space-around"}}>
                    <div>
                        <div><h3>Genera Descrizioni</h3></div>
                        <div className='flex-container-row'><a href={``}>Vedi abbonamento</a></div>
                        <div className='flex-container-row'><a href={`/descrizioniai?id=${utente?._id}`}>Vedi i download</a></div>
                        <div className='flex-container-row'><a href={`/contesto-utente?id=${utente?._id}`}>Vedi impostazioni</a></div>
                    </div>
                    <div>
                        <div><h3>Price Tracking</h3></div>
                        <div className='flex-container-row'><a href={``}>Vedi abbonamento</a></div>
                        <div className='flex-container-row'><a href={`/pricetracking?id=${utente?._id}`}>Vedi elenco prodotti</a></div>
                        <div className='flex-container-row'><a href={``}>Vedi impostazioni</a></div>
                    </div>
                </div>
                <br></br>


                <Button variant="contained" onClick={() => openDialogFunc(undefined)}>Nuovo mese</Button>
                <AbbonamentiUtente 
                    idUtente={utente?._id}
                    abbonamenti={abbonamenti}
                    openDialogFunc={openDialogFunc}
                />
                <DialogAbbonamento open={openDialog} onClose={closeDialog} onSave={closeDialogAndRefresh} abbonamento={abbonamentoSelezionato} abbonamentoUtente={abbonamentoUtente} idUtente={utente?._id}/>

                <div className='button-container'>
                    {utente && !utente.verificatoUtente && <p className='not-verified'>Non verificato</p>}

                    {utente && utente.verificatoUtente && !utente.autorizzatoUtente && <Button variant="contained" onClick={abilita}>Attiva (permetti accesso)</Button>}
                    {utente && utente.verificatoUtente && utente.autorizzatoUtente && <Button variant="contained" color="error" 
                        onClick={disabilita}>Disattiva (revoca accesso)</Button>}
                      

                </div>
            </div>   
            <LoadingView
                visible={loading}
            />
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default UtenteInfo;