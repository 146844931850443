import { useEffect, useState } from "react";
import LoginForm from "../components/auth/LoginForm";
import LoadingView from "../components/generics/LoadingView";
import Auth from "../api/Auth";
import Cookies from "js-cookie";
import Constants from "../utils/Constants";
import '../style/stylePages/Login.module.css';
import HexParser from "../utils/HexParser";


function Logout() {
    const [loading, setLoading] = useState(true);
    const [cookieChecked, setCookieChecked] = useState(false);

    const logout = async () => {
        setLoading(true);
        try {
            const res = await Auth.Logout();
            Cookies.remove(Constants.nomeCookie.nome!, { domain: Constants.domain.url, secure: true });
            const queryParameters = new URLSearchParams(window.location.search);
            let back = queryParameters.get("callback");
            if(back) {
                back = HexParser.hex_to_ascii(back);
            }
            else {
                //back = Constants.frontend.url!;
                back = "/";
                window.location.replace(back);
            }
        } 
        catch (error) {
            setLoading(false)
            throw error;
        }
    }

    useEffect(() => {
        let authCookie = Cookies.get(Constants.nomeCookie.nome!);
        if(authCookie) {
            let ck = JSON.parse(authCookie);
            if(ck) {
                logout();
            }
        }
    })

    return (
        <>
           <LoadingView visible={loading}/>
        </>
    );
  }
  
  export default Logout;