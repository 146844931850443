import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useState } from "react";

interface ComponentProps {
  id?: string;
  label?: string;
  required?: boolean;
  onChange(password: string): void;
}
export type Props = ComponentProps;

function PasswordField(props: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <OutlinedInput
        id={props.id}
        label={props.label}
        required={props.required}
        type={showPassword ? 'text' : 'password'}
        onChange={e => props.onChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              onMouseDown={togglePasswordVisibility}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
  );
};

export default PasswordField;