// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElencoProdotti_linkEsterno__Y5116 {
    color: gray;
    text-decoration: none !important;
    display: inline-block;
    max-width: 300px; /* Imposta la larghezza massima del link */
    direction: rtl; /* Imposta la direzione del testo da destra a sinistra */
    text-overflow: ellipsis; /* Aggiunge i puntini di sospensione */
    overflow: hidden; /* Nasconde il testo che eccede la larghezza */
    white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/style/styleComponents/priceTracking/ElencoProdotti.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gCAAgC;IAChC,qBAAqB;IACrB,gBAAgB,EAAE,0CAA0C;IAC5D,cAAc,EAAE,wDAAwD;IACxE,uBAAuB,EAAE,sCAAsC;IAC/D,gBAAgB,EAAE,8CAA8C;IAChE,mBAAmB;AACvB","sourcesContent":[".linkEsterno {\n    color: gray;\n    text-decoration: none !important;\n    display: inline-block;\n    max-width: 300px; /* Imposta la larghezza massima del link */\n    direction: rtl; /* Imposta la direzione del testo da destra a sinistra */\n    text-overflow: ellipsis; /* Aggiunge i puntini di sospensione */\n    overflow: hidden; /* Nasconde il testo che eccede la larghezza */\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkEsterno": `ElencoProdotti_linkEsterno__Y5116`
};
export default ___CSS_LOADER_EXPORT___;
