import { Link } from 'react-router-dom';
import Cookies from "js-cookie";
import Constants from "../utils/Constants";
import '../style/Menu.css';

const buildLoginButton = () => {
    let authCookie = Cookies.get(Constants.nomeCookie.nome!);
    if(authCookie) {
        let ck = JSON.parse(authCookie);
        if(ck) {
            return (
              <>
                {ck && ck.user && ck.user.ruolo && ck.user.ruolo === 'admin' && (
                  <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/utenti">
                      Utenti
                    </Link>
                  </li>
                )}
                {/*<li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/logout">
                    Logout
                  </Link>
                </li>*/}
              </>
            )
        }
    }
    return (
      <>      
        {/*<li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/signup">
            Registrati
          </Link>
        </li>*/}
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/login">
            Login
          </Link>
        </li>
      </>
    )
  }




function Menubar() {
  return (
    <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
              <a className="navbar-brand" href={process.env.REACT_APP_SITOWPURL}><img className="logoMenu" src="img/logo-xenua-scuro.png" /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <button className="close-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">X</button>
                  
                  <div className="container-top-menu-mobile">
                    <div className="logo"><a className="navbar-brand" href={process.env.REACT_APP_SITOWPURL}><img className="logoMenu" src="img/logo-xenua-scuro.png" /></a></div>
                  </div>

                  <div className="vociMenu">
                    {buildLoginButton()}
                  </div>
              </ul>
              </div>
          </div>
        </nav>
    </div>
  );
}
export default Menubar;