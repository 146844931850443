import { useEffect, useState } from 'react';
import styles from '../style/styleComponents/priceTracking/ElencoCompetitors.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import ApiProdotto from '../api/priceTracking/ApiProdotti';
import { Button } from '@mui/material';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { Prodotto } from '../models/priceTracking/prodottoPriceTracking';
import ElencoCompetitors from '../components/priceTracking/ElencoCompetitors';
import leggereNumeri from '../utils/leggereNumeri';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GraficoPrezzi from '../components/priceTracking/GraficoPrezzi';



function PriceTrackingProdotto() {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);
    const [prodotto, setProdotto] = useState<Prodotto>();
    const navigate = useNavigate();
  

    useEffect(() => {
        const urlProdotto = queryParameters.get("url");
        if(!prodotto) {
            getProdotto(urlProdotto);
        }
    })


    const getProdotto = async (urlProdotto: string | null) => {
        if(urlProdotto !== null) {
            setLoading(true);
            const result: Prodotto = await ApiProdotto.getSingoloProdotto(urlProdotto);
            setProdotto(result);
            setLoading(false);
        }
    }



    return (
        <>
            <div className="page-template">
                <div className="main-content pagina-impostazioni">
                    

                    <div className="titoloPagina">
                            <div>
                                <h1>Tracking Prodotto</h1>
                                <p>Tracking sul singolo prodotto</p>
                            </div>
                    </div>


                    <Button variant="contained" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
                        Torna ai prodotti
                    </Button>
                    <br></br><br></br>


                    <div className="box2col">
                        <div className="box box2colint">
                            <h2>Info Prodotto</h2>
                            <p>id: {prodotto ? prodotto._id : ""}</p>
                            <p>Nome del prodotto: {prodotto?.nomeProdottoCliente}</p>
                            <p>Stato del prodotto: {prodotto?.statoProdottoCliente ? "Attivo" : "Disattivo"}</p>
                            <p>Url del prodotto: {prodotto?.urlProdottoCliente}</p>
                        </div>

                        <div className="box box2colint"> 
                        <h2>Info prezzo</h2>  
                            <p>Prezzo del tuo prodotto: <span className={styles.prezzoClienteEvidenza}>{prodotto?.ultimoPrezzoCliente ? leggereNumeri.lavoraDecimal128(prodotto?.ultimoPrezzoCliente.prezzoUltimoPrezzoCliente) + "€" : "N/A"}</span></p>
                            <p>Prezzo medio dei competitor: {prodotto?.prezzoMedioCompetitor ? leggereNumeri.lavoraDecimal128(prodotto?.prezzoMedioCompetitor.prezzoPrezzoMedioCompetitor) + "€" : "N/A"}</p>
                            <p>Differenza prezzo: {prodotto?.differenzaPrezzoMedio ? leggereNumeri.lavoraDecimal128(prodotto?.differenzaPrezzoMedio.percDifferenzaPrezzoMedio) + "%" : "N/A"}</p>
                        </div>
                    </div>


                    <div className="box box1col">
                        <h2>Prodotti dei competitor</h2>
                        {prodotto ? (
                            <ElencoCompetitors prodotto={prodotto} />
                            ) : (
                            <div>Caricamento...</div>
                            )}
                    </div>

                    <div className="box box1col"> 
                        <h2>Andamento dei prezzi</h2>
                        <GraficoPrezzi prodotto={prodotto}></GraficoPrezzi>
                    </div>

                    <br></br><br></br>




                </div>
            </div>
        </>
    );
}

export default PriceTrackingProdotto;