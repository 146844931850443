// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blocco-esterno {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--grigioChiaro);
}
.blocco-contenuto {
    display: flex;
    position: relative;
    height: 100%;
    flex-grow: 4;
    width: 100%;
}

.contenuto-principale {
    width: 100%;
}


/*Titolo pagine*/
.titoloPagina {
    display: flex;
    margin-bottom: 20px;
}
.titoloPagina svg {
    color: var(--principale);
    margin-right: 8px;
    font-size: 30px;
    margin-top: 1px;
}`, "",{"version":3,"sources":["webpack://./src/style/Layout.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,qCAAqC;AACzC;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;;AAGA,gBAAgB;AAChB;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,wBAAwB;IACxB,iBAAiB;IACjB,eAAe;IACf,eAAe;AACnB","sourcesContent":[".blocco-esterno {\n    min-height: 100vh;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    background-color: var(--grigioChiaro);\n}\n.blocco-contenuto {\n    display: flex;\n    position: relative;\n    height: 100%;\n    flex-grow: 4;\n    width: 100%;\n}\n\n.contenuto-principale {\n    width: 100%;\n}\n\n\n/*Titolo pagine*/\n.titoloPagina {\n    display: flex;\n    margin-bottom: 20px;\n}\n.titoloPagina svg {\n    color: var(--principale);\n    margin-right: 8px;\n    font-size: 30px;\n    margin-top: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
