
import Axios from 'axios';
import Constants from "../../utils/Constants";
import Errors from "../../utils/Errors";
import Auth from "./../Auth";


export default class ApiCompetitor {

  /*Vedi i competitor di un prodotto*/
  static async getElencoCompetitor(urlProdotto: string | null, filtri?: any, page: number = 1, limit: number = 10, sort: string = 'differenzaPrezzo.percDifferenzaPrezzo', order: string = 'desc') {
        try {
            const token = Auth.getToken();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let filters = "";
            let fields = "";
            if(filtri.statoCompetitor || filtri.urlCompetitor) {
              filters += "filter=";
              fields += "&fields=";
              if(filtri.statoCompetitor) {
                filters += `${filtri.statoCompetitor},`;
                fields += `statoCompetitor,`;
              } 
              if(filtri.urlCompetitor) {
                filters += `${filtri.urlCompetitor},`;
                fields += `urlCompetitor,`;
              }
              filters = filters.replace(/,\s*$/, "");
              fields = fields.replace(/,\s*$/, "");
              fields += "&"
            }


            const encodedUrlProdotto = urlProdotto ? btoa(urlProdotto) : null;
            const res = await Axios.get(`${Constants.apiWebService.baseUrl}/pricetracking/elencocompetitor?urlProdotto=${encodedUrlProdotto}&${filters}${fields}page=${page}&limit=${limit}&sort=${sort}&order=${order}`, config);
            return res.data.data;
        }
        catch (err: any) {
            Errors.erroreApi(err.message);
            return err;
        }
  }



}