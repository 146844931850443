// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*Struttura*/
.upload-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
}


/*BOTTONE  DI UPLOAD*/
.upload-element-esterno {
    cursor: pointer;
    height: 80px;
    max-width: 300px;
    margin: 0px;
    text-align: center;
}
.upload-element-esterno .upload-element-interno {
    background: var(--principale);
    border-radius: 10px;
    padding: 25px;
    color: var(--white);
}
.upload-element-esterno .upload-element-interno .testo {
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
}



/*ELENCO FILE CARICATI*/
.file-caricati-esterno {
}
.file-caricati-interno {
    margin: 0px;
    max-width: 300px;
}
.file-caricati-interno .label-filecaricato {
    font-size: 16px;
}
.file-caricati-interno ul {
    font-size: 16px;
    font-weight: 600;
    padding: 0px;
}
.file-caricati-interno ul .icona-csv {
    width: 25px;
    margin-right: 5px;
}


/*BOTTONE INVIA*/
.bottone-invia-esterno {
    margin: 0px;
    max-width: 300px;
}
.bottone-invia-esterno button {
    font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/style/styleComponents/UploadFile.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,gBAAgB;AACpB;;;AAGA,qBAAqB;AACrB;IACI,eAAe;IACf,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;;;AAIA,uBAAuB;AACvB;AACA;AACA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,WAAW;IACX,iBAAiB;AACrB;;;AAGA,gBAAgB;AAChB;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB","sourcesContent":["/*Struttura*/\n.upload-container {\n    display: flex;\n    align-items: center;\n    flex-wrap: wrap;\n    gap: 20px;\n    margin-top: 10px;\n}\n\n\n/*BOTTONE  DI UPLOAD*/\n.upload-element-esterno {\n    cursor: pointer;\n    height: 80px;\n    max-width: 300px;\n    margin: 0px;\n    text-align: center;\n}\n.upload-element-esterno .upload-element-interno {\n    background: var(--principale);\n    border-radius: 10px;\n    padding: 25px;\n    color: var(--white);\n}\n.upload-element-esterno .upload-element-interno .testo {\n    margin: 0px;\n    font-size: 16px;\n    font-weight: 600;\n}\n\n\n\n/*ELENCO FILE CARICATI*/\n.file-caricati-esterno {\n}\n.file-caricati-interno {\n    margin: 0px;\n    max-width: 300px;\n}\n.file-caricati-interno .label-filecaricato {\n    font-size: 16px;\n}\n.file-caricati-interno ul {\n    font-size: 16px;\n    font-weight: 600;\n    padding: 0px;\n}\n.file-caricati-interno ul .icona-csv {\n    width: 25px;\n    margin-right: 5px;\n}\n\n\n/*BOTTONE INVIA*/\n.bottone-invia-esterno {\n    margin: 0px;\n    max-width: 300px;\n}\n.bottone-invia-esterno button {\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
