import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, TextField } from '@mui/material';
import React, { useState } from 'react';
import '../../style/styleComponents/auth/LoginForm.css';
import Constants from '../../utils/Constants';
import PasswordField from '../generics/PasswordField';

interface LoginProps {
  onLogin: (email: string, password: string, rimaniConnesso: boolean) => void;
}

const LoginForm: React.FC<LoginProps> = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rimaniConnesso, setRimaniConnesso] = useState(false);
  const [invioEseguito, setInvioEseguito] = useState(false);

  const handleRimaniConnesso = () => {
    setRimaniConnesso(!rimaniConnesso);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onLogin(email, password, rimaniConnesso);
    setInvioEseguito(true);
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className='login-form'>

        <div className="campi-form">
          <div className="riga-form">
            <FormControl variant="outlined">
              <FormHelperText>Email</FormHelperText>
              <TextField
                type="email"
                className={"campo-form"}
                required
                error={!email && invioEseguito}
                value={email || ""}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>
          </div>

          <div className="riga-form">
            <FormControl variant="outlined">
              <FormHelperText>Password</FormHelperText>
              <PasswordField
                id="outlined-password-input"
                required
                onChange={setPassword} />
              <a href={'/recupera'}>Password dimenticata?</a>
            </FormControl>
          </div>
        </div>

        <div className="checkboxes-form">
          <div className="riga-form">
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Rimani connesso"
                  labelPlacement="end"
                  value={rimaniConnesso}
                  checked={rimaniConnesso}
                  onChange={handleRimaniConnesso}
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>

        <button type="submit" className='btn-standard'>Login</button>
      </form>
    </div>
  );
};

export default LoginForm;
