import '../style/styleComponents/SidebarLeft.css';




function SidebarLeft() {
 



  return (
    <div>
     
     <div>
        <div className="riga"><a href="https://doagency.it/wp-content/uploads/2024/07/csv-esempio-input.csv ">Scarica il file CSV di esempio</a></div>
        <div className="riga"><a target="blank" href="https://doagency.it/contatti/">Se hai problemi contattaci</a></div>
        <div className="riga"><a target="blank" href={'https://doagency.it/soluzioni/xenua-ai/'}>Maggiori informazioni</a></div>
      </div>
    </div>
  );
}
export default SidebarLeft;