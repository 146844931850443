// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
    display: flex;
    background: #0f1a2f;
}
.footer-container .footer-interno {
    max-width: 1200px;
    margin: 0 auto; 
    padding: 10px 15px 10px 15px;
    color: white;
}

.footer-container .footer-interno p {
    margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/style/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,4BAA4B;IAC5B,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".footer-container {\n    display: flex;\n    background: #0f1a2f;\n}\n.footer-container .footer-interno {\n    max-width: 1200px;\n    margin: 0 auto; \n    padding: 10px 15px 10px 15px;\n    color: white;\n}\n\n.footer-container .footer-interno p {\n    margin-bottom: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
