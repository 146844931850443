import styles from '../style/styleComponents/Home.module.css';
import { useDropzone } from 'react-dropzone';
import { Popover } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import UploadFile from '../components/descrizioniAI/UploadFile';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Constants from '../utils/Constants';
import AbbonamentoApi from '../api/Abbonamenti';
import { Abbonamento, Mesi } from '../models/Abbonamento';
import LavoraDate from '../utils/LavoraDate';
import { Utente } from '../models/Utente';
import LoadingView from '../components/generics/LoadingView';
import Download from '@mui/icons-material';
import Downloads from '../components/descrizioniAI/Downloads';
import ApiImpostazioniUtenti from '../api/ImpostazioniUtenti';
import { ImpostazioniUtente } from '../models/ImpostazioniUtente';
import CreateIcon from '@mui/icons-material/Create';


function DescrizioniAI() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cookie, setCookie] = useState<String>();
  const [utente, setUtente] = useState<Utente>();
  const [infoAbbonamentoInCorso, setInfoAbbonamentoInCorso] = useState<Mesi>();
  const [impostazioniUtente, setImpostazioniUtente] = useState<ImpostazioniUtente>();


  useEffect(() => {
    if (!cookie) {
      let authCookie = Cookies.get(Constants.nomeCookie.nome!);
      setCookie(authCookie);
      if(authCookie) {
          let ck = JSON.parse(authCookie);
          if(ck) {
            var utenteM : Utente = { _id: ck.user._id, ruolo: ck.user.ruolo, nome: ck.user.nome, cognome: ck.user.cognome };
            setUtente(utenteM);
            return;
          }
      }     
    }
    navigate(`/login`);
  }, []);


  useEffect(() => {
    if (!infoAbbonamentoInCorso) {
      AbbonamentoF();
    }
    
  }, [infoAbbonamentoInCorso]);
 
  
  useEffect(() => {
    if (utente?._id) {
        getImpostazioni(utente._id);
    }
}, [utente]);



  const getImpostazioni = async (idUtente: string | null) => {
    if(idUtente !== null) {
      setLoading(true);
      let imp: ImpostazioniUtente = await ApiImpostazioniUtenti.getImpostazioniUtente(idUtente);
      if(!imp) {
        imp = {
          idUtente: idUtente,
          generaDescrizioni: {
            contesto: ""  
          }
        }
      }
      setImpostazioniUtente(imp);
      setLoading(false);
    }
  }

  

  const AbbonamentoF = async () => {
    setLoading(true);
    try {
      const responseinfoAbbonamentoNow = await AbbonamentoApi.InfoAbbonamentoInCorso();
      if (responseinfoAbbonamentoNow.response && responseinfoAbbonamentoNow.response.status && responseinfoAbbonamentoNow.response.status == 404) {
        let infoAbbonamentoInCorsoNow: Mesi = {
          saldoRimanente: -1,
          dataInizio: "Abbonamento Mancante",
          dataFine: "2023-09-25T00:00:00",
        };
        setInfoAbbonamentoInCorso(infoAbbonamentoInCorsoNow);
      }
      else  {
        if (responseinfoAbbonamentoNow) {
          setInfoAbbonamentoInCorso(responseinfoAbbonamentoNow.data);
        } 
      }

    }
    catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
    setLoading(false);

  }


  const onChangeinfoAbbonamentoInCorso = () => {
    AbbonamentoF();
  }


  return (
    <div className="page-template">
      <div className="main-content">
      
          <div className="titoloPagina">
            <CreateIcon></CreateIcon>
            <div>
              <h1>Descrizioni prodotto</h1>
              <p>Genera le descrizioni prodotto tramite AI</p>
            </div>
          </div>


        <div className="box2col">
          <div className="box box2colint">
            <h2>Abbonamento</h2>
            <p>
              Descrizioni ancora generabili questo mese (fino al <b>{infoAbbonamentoInCorso ? LavoraDate.lavoraDataConMese(new Date(infoAbbonamentoInCorso.dataFine!))  : "Attendere il caricamento"}</b>): <b>{infoAbbonamentoInCorso ? infoAbbonamentoInCorso.saldoRimanente!.toString() : "Attendere il caricamento"}</b>
            </p>
            <p>
              Scadenza abbonamento: <b>{infoAbbonamentoInCorso && infoAbbonamentoInCorso.dataFineAbbonamento ? LavoraDate.lavoraDataConMese(new Date(infoAbbonamentoInCorso.dataFineAbbonamento!))  : "Nessun abbonamento in corso."}</b>
            </p>
          </div>

          <div className="box box2colint">
            <h2>Contesto</h2>
            <p>{impostazioniUtente && impostazioniUtente.generaDescrizioni?.contesto ? impostazioniUtente.generaDescrizioni?.contesto : "Ancora nessun contesto."}</p>
          </div>
        </div>


        <div className="box2col">
          <div className="box box2colint">
            <h2>Istruzioni</h2>
            <ol>
              <li><a href="https://doagency.it/wp-content/uploads/2024/07/csv-esempio-input.csv">Scarica il file CSV di esempio</a></li>
              <li>Compila il file CSV con i tuoi prompt.</li>
              <li>Se vuoi, chiudi il tab e torna quando vuoi su questa pagina nella sezione "File lavorati" in basso, per scaricare il CSV pronto. Se serve, fai un refresh della pagina per aggiornare lo stato di lavorazione del file.</li>
            </ol>
          </div>

          <div className="box box2colint">
            <h2>Carica il file</h2>
            {infoAbbonamentoInCorso && infoAbbonamentoInCorso.dataFineAbbonamento && infoAbbonamentoInCorso.saldoRimanente ? (
              <UploadFile 
                infoAbbonamentoInCorso={infoAbbonamentoInCorso?.saldoRimanente} 
                onChangeinfoAbbonamentoInCorso={onChangeinfoAbbonamentoInCorso} 
              />
            ) : "Nessun abbonamento in corso."}
          </div>
        </div>


     
        <div className="box box1col">
          <h2>File lavorati</h2>
          <Downloads/>
        </div>
        <br></br><br></br>

      </div>

        <LoadingView visible={loading} />
    </div>
  );
}

export default DescrizioniAI;