import Axios from 'axios';
import Constants from "../../utils/Constants";
import Errors from "../../utils/Errors";
import Auth from "../Auth";



export default class ApiProdotti {
  
  /*Importa prodotti tramite csv*/
  static async Upload(formData: FormData, utenteId: string | null) {
        try {
            const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };
            let res; 
            if(utenteId) {
              res = await Axios.post(Constants.apiWebService.baseUrl + "/pricetracking/importaprodotti/" + utenteId, formData, config);
            }
            else {
              res = await Axios.post(Constants.apiWebService.baseUrl + "/pricetracking/importaprodotti/", formData, config);
            }
            return res;
          }
          catch (err : any) {
            Errors.erroreApi(err.message);  
            return err;
          }  
  }


  /*Vedi i prodotti di un utente*/
  static async getElencoProdotti(utenteId: string | null, filtri?: any, page: number = 1, limit: number = 10, sort: string = 'differenzaPrezzoMedio.percDifferenzaPrezzoMedio', order: string = 'desc') {
    try {
        const token = Auth.getToken();
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let filters = "";
        let fields = "";
        if(filtri.statoProdottoCliente || filtri.nomeProdotto || filtri.urlProdotto) {
          filters += "filter=";
          fields += "&fields=";
          if(filtri.statoProdottoCliente) {
            filters += `${filtri.statoProdottoCliente},`;
            fields += `statoProdottoCliente,`;
          } 
          if(filtri.nomeProdotto) {
              filters += `${filtri.nomeProdotto},`;
              fields += `nomeProdottoCliente,`;
          }
          if(filtri.urlProdotto) {
            filters += `${filtri.urlProdotto},`;
            fields += `urlProdottoCliente,`;
          }
          filters = filters.replace(/,\s*$/, "");
          fields = fields.replace(/,\s*$/, "");
          fields += "&"
        }


        let res;
        if (utenteId) {
          // Passaggio dei parametri per paginazione e ordinamento nella query string
          res = await Axios.get(`${Constants.apiWebService.baseUrl}/pricetracking/elencoprodottibyid?id=${utenteId}&${filters}${fields}page=${page}&limit=${limit}&sort=${sort}&order=${order}`, config);
        }
        else {
          res = await Axios.get(`${Constants.apiWebService.baseUrl}/pricetracking/elencoprodotti?${filters}${fields}page=${page}&limit=${limit}&sort=${sort}&order=${order}`, config);
        }
        return res;
    }
    catch (err: any) {
        Errors.erroreApi(err.message);
        return err;
    }
  }



  /*Get Singolo Prodotto*/
  static async getSingoloProdotto(urlProdotto: string | null) {
    try {
        const token = Auth.getToken();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };

        const encodedUrlProdotto = urlProdotto ? btoa(urlProdotto) : null;
        let res; 
        if(encodedUrlProdotto) {
          res = await Axios.get(Constants.apiWebService.baseUrl + "/pricetracking/prodotto?urlProdotto=" + encodedUrlProdotto, config);
        }
        res = res?.data;
        return res;
      }
      catch (err : any) {
        Errors.erroreApi(err.message);  
        return err;
      }  
  }




}

