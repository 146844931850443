import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Cookies from "js-cookie";
import Auth from "./Auth";

export default class Files {
  
    static async Upload(formData: FormData) {
        try {
            const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };
            const res = await Axios.post(Constants.apiWebService.baseUrl + "/upload/uploadfile", formData, config);
            return res;
          }
          catch (err : any) {
            Errors.erroreApi(err.message);  
            return err;
          }  
    }


  //Vedi tutti i file di un utente
  static async DownloadTuttiFile(id: string | null) {
    try {
      const token = Auth.getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let res; 
      if(id)
        res = await Axios.get(Constants.apiWebService.baseUrl + "/upload/getElencoUploadById/" + id, config);
      else
        res = await Axios.get(Constants.apiWebService.baseUrl + "/upload/getElencoUpload", config);
      return res;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }  
  }

  //Scarica file di un utente
  static async DownloadSingoloFile(nomeBlobFile: string, id: string = "") {
    try {
      const token = Auth.getToken();
      const config = {
        headers:  { Authorization: `Bearer ${token}`}
      };
      const res = await Axios.get(Constants.apiWebService.baseUrl + "/upload/downloadfile/" + nomeBlobFile + "?idUtente=" + id, config);
      return res;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }  
  }


    //Elimina un file
    static async EliminaFile(_id: string, nomeBlobFile: string) {
      try {
        const token = Auth.getToken();
        const config = {
          headers:  { Authorization: `Bearer ${token}`}
        };
        const res = await Axios.delete(Constants.apiWebService.baseUrl + "/upload/cancellafile/" + _id + '/' + nomeBlobFile, config);
        return res;
      }
      catch (err : any) {
        Errors.erroreApi(err.message);  
      }  
    }




}

