import { useEffect, useState } from "react";
import Auth from "../api/Auth";
import LoadingView from "../components/generics/LoadingView";
import { ToastContainer } from "react-toastify";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";
import LockResetIcon from '@mui/icons-material/LockReset';

function ResetPassword() {
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState<string | null>(null);
    const [verified, setVerified] = useState(false);

    const verify = async (password: string) => {
        setLoading(true);
        if(id !== null)
            await Auth.ResetPassword(id, password);  

        setVerified(true);
        let back = "/login";
        window.location.replace(back);
    }

    useEffect(() => {
        if(!verified) {
            const queryParameters = new URLSearchParams(window.location.search);
            const idReset = queryParameters.get("id")
            setId(idReset);
        }
    });

    return (
        <>
            <div className="page-template">
                <div className="main-content">  
                    

                    <div className="titoloPagina">
                        <LockResetIcon></LockResetIcon><h1>Reset password</h1>
                    </div>

                    
                    <div className="box box1col">
                        <h2>Reset</h2>
                        <ResetPasswordForm onSend={verify}/>
                    </div>


                    
                </div>
            </div>
            <LoadingView visible={loading}/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}

export default ResetPassword;